import React from "react";
import CandleChart from "./CandleChart";
import { useOnChainData } from "../../../../../common/hooks/use-onchain-data";
import { RestAPI } from "../../../../../packages/neo/api";
import { MAINNET } from "../../../../../consts/global";
import { Typography } from "antd";

const GASNEO = () => {
  const { data, isLoaded, error } = useOnChainData(() => {
    return new RestAPI(MAINNET).getGASNEOPriceCandle();
  }, []);

  return (
    <div>
      <Typography.Title level={3}>NEO/GAS Ratio (1d)</Typography.Title>
      {isLoaded && <CandleChart data={data} />}
    </div>
  );
};

export default GASNEO;
